<ng-container *ngIf="(source$ | async) as source">
  <div *ngIf="!isFailed" class="item-snippet" [class.loading]="isLoading"  [class]="theme" [class.no-hover-effect]="noHoverEffects">
    <ng-container [ngSwitch]="type">

      <div class="image" *ngSwitchCase="'image'" data-id="imageContainer">
          <img
            #imageElement
            class="pointer"
            data-id="sourceImage"
            [src]="source"
            [alt]="source"
            (error)="imageError.emit(); isFailed = true"
            (click)="imageClicked.emit({src: source})"
            (load)="loadedMedia()"
            [class.loading]="isLoading"
          >
          <div *ngIf="!noHoverEffects" class="toolbar top">
            <div class="clickable-icon d-flex align-items-center justify-content-center pointer"
                (click)="createEmbedCodeClicked($event)"
                smTooltip="Copy embed code"
                data-id="copyToReportButton">
              <i class="al-icon al-ico-code sm"></i>
            </div>
          </div>
      </div>

      <div class="player" data-id="videoContainer" *ngSwitchCase="'player'">
        <video
          *ngIf="!noHoverEffects"
          #video
          preload="metadata"
          [src]="source"
          (error)="imageError.emit(); isFailed = true"
          (loadedmetadata)="loadedMedia()"
          (click)="imageClicked.emit({src: source})"
        ></video>
        <video
          *ngIf="noHoverEffects"
          #video
          controls
          preload="metadata"
          data-id="videoContainer"
          [src]="source"
          (error)="imageError.emit(); isFailed = true"
          (loadedmetadata)="loadedMedia()"
          (click)="imageClicked.emit({src: source})"
        ></video>
        <div  class="toolbar top">
          <div *ngIf="!noHoverEffects" class="clickable-icon d-flex align-items-center justify-content-center pointer"
               (click)="createEmbedCodeClicked($event)"
               smTooltip="Copy embed code" data-id="CopyToReportButton">
            <i class="al-icon al-ico-code sm"></i>
          </div>
        </div>
      </div>

      <div class="html-snippet" *ngSwitchCase="'html'">
        <div class="html-wrap" [class.loading]="isLoading">
          <iframe
            class="html-viewer"
            data-id="htmlViewer"
            width="400" height="400"
            referrerpolicy="origin-when-cross-origin"
            sandbox="allow-scripts sallow-popups allow-same-origin allow-storage-access-by-user-activation"
            [src]="source | safe: 'url'"
            (load)="iframeLoaded($event)"
          ></iframe>
        </div>
        <div *ngIf="!noHoverEffects" class="toolbar">
          <div class="clickable-icon d-flex align-items-center justify-content-center pointer"
               (click)="createEmbedCodeClicked($event)"
               smTooltip="Copy embed code" data-id="CopyToReportButton">
            <i class="al-icon al-ico-code sm"></i>
          </div>
          <div class="clickable-icon d-flex align-items-center justify-content-center pointer"
               ngxClipboard
               smTooltip="Copy URL"
               data-id="CopyURLButton"
               [cbContent]="source"
               (cbOnSuccess)="copyToClipboardSuccess(true)">
            <i class="al-icon al-ico-copy-to-clipboard sm"></i>
          </div>
          <div class="clickable-icon d-flex align-items-center justify-content-center pointer"
               smTooltip="Open URL in a new Tab"
               data-id="openURLInNewTab"
               (click)="openInNewTab(source)"
          >
            <i class="al-icon al-ico-export sm"></i>
          </div>
        </div>
      </div>

    </ng-container>
    <div *ngIf="!isLoading" class="image-var ellipsis" [smTooltip]="frame?.variant" smShowTooltipIfEllipsis
    >{{frame?.variantAndMetric || frame?.variant}}</div>
  </div>
</ng-container>

<ng-container *ngIf="(source$ | async) as source">
  <div *ngIf="isFailed" class="item-snippet">
    <sm-snippet-error
      [theme]="theme"
      [copyContent]="source?.split('?X-Amz-Date')[0]"
      (openImageClicked)="openInNewTab(source)"
    ></sm-snippet-error>
  </div>
</ng-container>
